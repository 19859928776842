export const errorResponse = async (err: unknown, key?: string) => {
  const errorResponse = err as Response;
  try {
    const errors = await errorResponse.json().then((a) => a);

    const responseErrors = errors['errors'] || errors;

    return convertToNestedObject(responseErrors);
  } catch {
    if (key) {
      return { [key]: { general: errorResponse.statusText } };
    } else {
      return { general: errorResponse.statusText };
    }
  }
};

export function convertToNestedObject(input: { [key: string]: string }) {
  const result: { [key: string]: unknown } = {};

  for (const key in input) {
    const parts = key.split('.');
    let current = result;

    for (let i = 0; i < parts.length; i++) {
      const part = camelize(parts[i]);
      if (!current[part]) {
        if (i === parts.length - 1) {
          current[part] = input[key];
        } else {
          current[part] = {};
        }
      }

      if (current[part][0]) {
        current = current[part][0];
      } else {
        current = current[part];
      }
    }
  }

  return result;
}

const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, '');
};
