import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import { Configuration, ProductState } from '../Types/product';
import { CartState } from '../Types/cart';
import { get } from '../Services/http';
import { errorResponse } from '../errorResponse';

const sliceName = 'product';
const rootRoute = '/api/product';
const initialState: ProductState = {
  variants: [],
  isLoggedIn: false,
  configurations: [],
  ...((window.__litium.preloadState?.product || {}) as Partial<ProductState>),
};

export const getConfigurations = createAsyncThunk<Configuration[], string>(
  `${sliceName}/getConfigurations`,
  async (articleNumber, { rejectWithValue }) => {
    try {
      const response = await get(
        `${rootRoute}/configurations/${encodeURIComponent(articleNumber)}`,
      );
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'product'));
    }
  },
);

export const productSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setArticleNumber: (state, action: PayloadAction<string>) => ({
      ...state,
      articleNumber: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getConfigurations.fulfilled, (state, action) => {
      state.configurations = action.payload;
    });
  },
});

export const { setArticleNumber } = productSlice.actions;

export default productSlice.reducer;
