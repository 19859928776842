/**
 * Scroll browser window to top.
 */
const scrollToTop = (behavior: ScrollBehavior = 'smooth') => {
  window.scroll({
    top: 0,
    left: 0,
    behavior,
  });
};

export default scrollToTop;
