export const mainNavigationHandler = () => {
  const header = document.querySelector('#header');

  const mainNavigationClass = 'main-navigation';

  const mainNavigation = document.querySelector(`.${mainNavigationClass}`);
  if (!mainNavigation) {
    return;
  }

  const closeAllNavigationItems = () => {
    Array.from(
      document.getElementsByClassName(`${mainNavigationClass}__item--open`)
    ).forEach((element) => {
      element.classList.remove(`${mainNavigationClass}__item--open`);
    });
  };

  const navNextButtons = mainNavigation.querySelectorAll(
    '.main-navigation__next'
  );
  Array.from(navNextButtons).forEach((element) => {
    element.addEventListener('click', () => {
      element.parentElement && element.parentElement.classList.add('open');
    });
  });

  const navPrevButtons = mainNavigation.querySelectorAll(
    '.main-navigation__prev'
  );
  Array.from(navPrevButtons).forEach((element) => {
    element.addEventListener('click', () => {
      const closestOpen = element.closest('.open');
      closestOpen && closestOpen.classList.remove('open');
    });
  });

  const mainNavigationToggle = document.querySelector('#MainNavigationToggle');
  if (mainNavigationToggle) {
    mainNavigationToggle.addEventListener('click', () => {
      const className = `${mainNavigation.classList[0]}--open`;
      const isOpen = mainNavigation.classList.contains(className);

      mainNavigation.classList.toggle(className, !isOpen);
      mainNavigationToggle.classList.toggle('open', !isOpen);

      if (header) {
        header.classList.toggle('header--main-navigation-open', !isOpen);
      }

      if (isOpen) {
        Array.from(mainNavigation.querySelectorAll('.open')).forEach(
          (element) => element.classList.remove('open')
        );
      }
    });
  }

  const itemsOnLevel0 = mainNavigation.querySelectorAll(
    `.${mainNavigationClass}__list--level-0 > .${mainNavigationClass}__item--has-children > .${mainNavigationClass}__link`
  );
  Array.from(itemsOnLevel0).forEach((item) => {
    item.addEventListener('click', (e) => {
      if (item && window.innerWidth >= 1024) {
        const itemWrapper = item.closest(`.${mainNavigationClass}__item`);
        if (itemWrapper) {
          const isOpen = itemWrapper.classList.contains(
            `${itemWrapper.classList[0]}--open`
          );
          if (!isOpen) {
            e.preventDefault();
            closeAllNavigationItems();
            itemWrapper.classList.add(`${itemWrapper.classList[0]}--open`);
          }
        }
      }
    });
  });

  document.addEventListener('click', (e) => {
    if (!mainNavigation.contains(e.target as Element)) {
      closeAllNavigationItems();
    }
  });

  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      closeAllNavigationItems();
    }
  });
};
