import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get, post, remove } from '../Services/http';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import { errorResponse } from '../errorResponse';
import { Order, OrderHistoryState } from '../Types/orderHistory';
import { translate } from '../Services/translation';
import { showNotification } from './notificationSlice';
import { bool } from 'yup';
import { RootState } from '../store';
import { AddToCartModel, CartState } from '../Types/cart';

const sliceName = 'orderHistory';
const rootRoute = '/api/orderHistory';
const initialState: OrderHistoryState = {
  orders: [],
  totalCount: 0,
  ordersPerPage: 0,
  onlyMyOrders: false,
  onlyWaitingApproval: false,
};

export const getOrder = createAsyncThunk<Order, string>(
  `${sliceName}/getOrder`,
  async (orderId, { getState, rejectWithValue }) => {
    try {
      const response = await get(`${rootRoute}/${orderId}`);
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'orderHistory'));
    }
  },
);

export const getOrders = createAsyncThunk<OrderHistoryState, { page: number }>(
  `${sliceName}/getOrders`,
  async ({ page }, { getState, rejectWithValue }) => {
    try {
      const { onlyMyOrders, onlyWaitingApproval } = (getState() as RootState)
        .orderHistory;
      const response = await get(
        `${rootRoute}?page=${page}&onlyMyOrders=${onlyMyOrders}&onlyWaitingApproval=${onlyWaitingApproval}`,
      );
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'orderHistory'));
    }
  },
);

export const approveOrder = createAsyncThunk<Order, string>(
  `${sliceName}/approveOrder`,
  async (orderId, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await post(`${rootRoute}/${orderId}/approve`, null);

      dispatch(
        showNotification({
          text: translate('orderHistory.notification.orderApproved'),
          timeout: 4000,
        }),
      );

      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'orderHistory'));
    }
  },
);

export const declineOrder = createAsyncThunk<Order, string>(
  `${sliceName}/declineOrder`,
  async (orderId, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await post(`${rootRoute}/${orderId}/decline`, null);

      dispatch(
        showNotification({
          text: translate('orderHistory.notification.orderDeclined'),
          timeout: 4000,
        }),
      );

      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'orderHistory'));
    }
  },
);

export const removeOrder = createAsyncThunk<Order, string>(
  `${sliceName}/removeOrder`,
  async (orderId, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await remove(`${rootRoute}/${orderId}`);

      dispatch(setActiveOrder(undefined));

      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'orderHistory'));
    }
  },
);

export const addToOrder = createAsyncThunk<Order, AddToCartModel>(
  `${sliceName}/addToOrder`,
  async ({ orderId, ...data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await post(`${rootRoute}/${orderId}/addToOrder`, data);

      dispatch(
        showNotification({
          text: translate('cart.notification.addedToOrder'),
          timeout: 2000,
        }),
      );

      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'orderHistory'));
    }
  },
);

export const removeFromOrder = createAsyncThunk<
  Order,
  { orderId: string; rowSystemId: string }
>(
  `${sliceName}/removeFromOrder`,
  async ({ orderId, rowSystemId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await remove(`${rootRoute}/${orderId}/${rowSystemId}`);
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'orderHistory'));
    }
  },
);

export const orderHistorySlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setActiveOrder: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      if (action.payload) {
        state.activeOrder =
          state.orders.find((x) => x.orderId === action.payload) ||
          ({ orderId: action.payload } as Order);
      } else {
        state.activeOrder = undefined;
      }

      const urlParams = new URLSearchParams(window.location.search);
      if (state.activeOrder?.orderId) {
        urlParams.set('orderId', state.activeOrder?.orderId);
      } else {
        urlParams.delete('orderId');
      }

      history.pushState(
        null,
        '',
        urlParams.toString()
          ? `?${urlParams.toString()}`
          : window.location.pathname,
      );
    },
    setOnlyWaitingApproval: (state, action: PayloadAction<boolean>) => {
      state.onlyWaitingApproval = action.payload;
    },
    setOnlyMyOrders: (state, action: PayloadAction<boolean>) => {
      state.onlyMyOrders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrder.fulfilled, (state, action) => {
        state.activeOrder = action.payload;
      })
      .addCase(approveOrder.fulfilled, (state, action) => {
        if (state.activeOrder) {
          state.activeOrder = action.payload;
        }

        const existingIndex = state.orders.findIndex(
          (x) => x.orderId === action.payload.orderId,
        );
        if (existingIndex >= 0) {
          state.orders[existingIndex] = action.payload;
        }
      })
      .addCase(declineOrder.fulfilled, (state, action) => {
        if (state.activeOrder) {
          state.activeOrder = action.payload;
        }

        const existingIndex = state.orders.findIndex(
          (x) => x.orderId === action.payload.orderId,
        );
        if (existingIndex >= 0) {
          state.orders[existingIndex] = action.payload;
        }
      })
      .addCase(getOrders.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }))
      .addCase(addToOrder.fulfilled, (state, action) => {
        state.activeOrder = action.payload;
      })
      .addCase(removeFromOrder.fulfilled, (state, action) => {
        state.activeOrder = action.payload;
      });
  },
});

export const { setActiveOrder, setOnlyMyOrders, setOnlyWaitingApproval } =
  orderHistorySlice.actions;

export default orderHistorySlice.reducer;
