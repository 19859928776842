const headerElement = document.getElementById('header');

/**
 * Att scroll class to header element on scroll.
 */
const headerScrollClass = (offset = 10) => {
  if (headerElement) {
    window.addEventListener('scroll', () => {
      headerElement.classList.toggle('header--scroll', window.scrollY > offset);
    });
    headerElement.classList.toggle('header--scroll', window.scrollY > offset);

    const firstBlock = document.querySelector('.block');
    if (firstBlock?.className.includes('hero-')) {
      headerElement.classList.add('header--hero-first');
    }

    Array.from(document.querySelectorAll('.main-navigation__toggle')).forEach(
      (toggle) => {
        toggle.addEventListener('click', () => {
          if (toggle.parentElement) {
            toggle.parentElement.classList.toggle(
              `${toggle.parentElement.classList[0]}--open`
            );
          }
        });
      }
    );
  }
};

export default headerScrollClass;
