/**
 * Show or hide language selector
 * @param element
 */
const toggleLanguageSelector = (element: Element) => {
  const parent = element.parentElement as HTMLElement;
  const isOpen = parent.classList.contains(`${parent.classList[0]}--open`);
  if (parent) {
    const clickOutside = (e: MouseEvent) =>
      !parent.contains(e.target as HTMLElement) &&
      toggleLanguageSelector(element);

    if (!isOpen) {
      document.addEventListener('click', clickOutside);
    } else {
      document.removeEventListener('click', clickOutside);
    }

    parent.classList.toggle(`${parent.classList[0]}--open`, !isOpen);
  }
};

export default toggleLanguageSelector;
