import {
  createAsyncThunk,
  createSlice,
  isPending,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import {
  AdditionalOrderInfo,
  CheckoutMode,
  CheckoutModuleEnum,
  CheckoutState,
  CustomerDetails,
  DeliveryMethod,
  PaymentMethod,
} from '../Types/checkout';
import { post, put, remove } from '../Services/http';
import { errorResponse } from '../errorResponse';
import { RootState } from '../store';
import { showNotification } from './notificationSlice';
import { translate } from '../Services/translation';
import { refreshCart } from './cartSlice';
import { Address } from '../Types/myPages';

const sliceName = 'checkout';
const rootRoute = '/api/checkout';
const initialState: CheckoutState = {
  deliveryMethods: [],
  paymentMethods: [],
  companyAddresses: [],
  deliveryAddresses: [],
  customerDetails: {},
  authenticated: false,
  acceptTermsOfCondition: false,
  showAlternativeAddress: false,
  checkoutMode: CheckoutMode.CompanyCustomers,
  signUp: false,
  isBusinessCustomer: false,
  success: false,
  errorMessages: {},
  checkoutUrl: '',
  loginUrl: '',
  openModule: CheckoutModuleEnum.CustomerInfo,
  usedDiscountCodes: [],
  additionalOrderInfo: {},
  ...((window.__litium.preloadState?.checkout?.payload ||
    {}) as Partial<CheckoutState>),
};

let abortController: AbortController;

export const placeOrder = createAsyncThunk(
  `${sliceName}/placeOrder`,
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const checkoutState = (getState() as RootState).checkout;
      const response = await post(rootRoute, checkoutState);

      dispatch(
        showNotification({
          text: translate('checkout.notification.orderPlacementSuccessful'),
          timeout: 20000,
        })
      );

      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'checkout'));
    }
  }
);

export const updateCustomerDetails = createAsyncThunk<CheckoutState>(
  `${sliceName}/updateCustomerDetails`,
  async (_, { getState, rejectWithValue }) => {
    abortController && abortController.abort();
    abortController = new AbortController();

    try {
      const checkoutState = (getState() as RootState).checkout;
      const response = await put(
        `${rootRoute}/setCustomerDetail`,
        checkoutState,
        abortController
      );
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'checkout'));
    }
  }
);

export const reloadPayment = createAsyncThunk<CheckoutState>(
  `${sliceName}/reloadPayment`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const checkoutState = (getState() as RootState).checkout;
      const response = await put(
        '/api/checkout/reloadPaymentWidget',
        checkoutState
      );
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'checkout'));
    }
  }
);

export const setPaymentProvider = createAsyncThunk<CheckoutState>(
  `${sliceName}/setPaymentProvider`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const checkoutState = (getState() as RootState).checkout;
      const response = await put('/api/checkout/setPaymentProvider', {
        ...checkoutState,
        selectedPaymentMethod: undefined,
      } as CheckoutState);
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'checkout'));
    }
  }
);

export const setDeliveryProvider = createAsyncThunk<CheckoutState>(
  `${sliceName}/setDeliveryProvider`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const checkoutState = (getState() as RootState).checkout;
      const response = await put('/api/checkout/setDeliveryProvider', {
        ...checkoutState,
        selectedDeliveryMethod: undefined,
      } as CheckoutState);
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'checkout'));
    }
  }
);

export const setDiscountCode = createAsyncThunk<CheckoutState, string>(
  `${sliceName}/setDiscountCode`,
  async (discountCode, { getState, rejectWithValue, dispatch }) => {
    try {
      const checkoutState = (getState() as RootState).checkout;
      const response = await put('/api/checkout/setDiscountCode', {
        ...checkoutState,
        discountCode,
      });

      dispatch(refreshCart());

      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'checkout'));
    }
  }
);

export const deleteDiscountCode = createAsyncThunk<CheckoutState, string>(
  `${sliceName}/deleteDiscountCode`,
  async (discountCode, { getState, rejectWithValue, dispatch }) => {
    try {
      const checkoutState = (getState() as RootState).checkout;
      const response = await remove('/api/checkout/deleteDiscountCode', {
        ...checkoutState,
        discountCode,
      });

      dispatch(refreshCart());

      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'checkout'));
    }
  }
);

export const checkoutSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setNextModule: (state) => {
      state.openModule++;
      state.errorMessages = {};
    },
    setOpenModule: (state, action: PayloadAction<CheckoutModuleEnum>) => {
      state.openModule = action.payload;
      state.errorMessages = {};
    },
    setSelectedCompanyAddressId: (state, action: PayloadAction<string>) => {
      state.selectedCompanyAddressId = action.payload;
    },
    setSelectedDeliveryAddressId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.selectedDeliveryAddressId = action.payload;
      state.customDeliveryAddress = state.selectedDeliveryAddressId
        ? state.customDeliveryAddress
        : {};
    },
    setCustomDeliveryAddress: (
      state,
      action: PayloadAction<Partial<Address>>
    ) => {
      state.customDeliveryAddress = action.payload;
    },
    setSelectedDeliveryMethod: (
      state,
      action: PayloadAction<DeliveryMethod>
    ) => {
      state.selectedDeliveryMethod = action.payload;
    },
    setSelectedPaymentMethod: (state, action: PayloadAction<PaymentMethod>) => {
      state.selectedPaymentMethod = action.payload;
    },
    setDetails: (
      state,
      action: PayloadAction<{
        key: string;
        value: string;
      }>
    ) => {
      state.customerDetails[action.payload.key as keyof CustomerDetails] =
        action.payload.value;
    },
    setCustomerDetails: (
      state,
      action: PayloadAction<Partial<CustomerDetails>>
    ) => {
      state.customerDetails = { ...state.customerDetails, ...action.payload };
    },
    setAdditionalOrderInfo: (
      state,
      action: PayloadAction<Partial<AdditionalOrderInfo>>
    ) => {
      state.additionalOrderInfo = {
        ...state.additionalOrderInfo,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCustomerDetails.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }))
      .addCase(reloadPayment.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }))
      .addCase(setPaymentProvider.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }))
      .addCase(setDiscountCode.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }))
      .addCase(deleteDiscountCode.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }))
      .addCase(placeOrder.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }))
      .addMatcher(isPending, (state) => {
        state.errorMessages = {};
      })
      .addMatcher(isRejectedWithValue, (state, action) => {
        state.errorMessages = action.payload as { [key: string]: string };
      });
  },
});

export const {
  setNextModule,
  setOpenModule,
  setCustomerDetails,
  setAdditionalOrderInfo,
  setSelectedCompanyAddressId,
  setSelectedDeliveryAddressId,
  setCustomDeliveryAddress,
  setSelectedDeliveryMethod,
  setSelectedPaymentMethod,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
