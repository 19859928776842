import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';

export type ExpandableState = {
  [key: string]: string | null | undefined;
};

const sliceName = 'expandable';
const initialState: ExpandableState = {};

export const expandableSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    toggleGroup: (
      state,
      action: PayloadAction<{ groupId?: string; id?: string }>
    ) => {
      const { groupId, id } = action.payload;
      if (groupId) {
        if (state[groupId] === id) {
          delete state[groupId];
        } else {
          state[groupId] = id;
        }
      }
    },
  },
});

export const { toggleGroup } = expandableSlice.actions;

export default expandableSlice.reducer;
