/**
 * Scroll to specific element's position in DOM.
 */
const scrollToPosition = (
  queryOrElement: string | HTMLElement | undefined | null,
  scrollBehavior: ScrollBehavior = 'smooth',
  offset?: number
) => {
  if (queryOrElement) {
    const headerElement = document.getElementById('header');
    const element =
      typeof queryOrElement === 'string'
        ? document.querySelector(queryOrElement)
        : queryOrElement;
    if (element) {
      const rect = element.getBoundingClientRect();
      window.scroll({
        top:
          window.scrollY +
          rect.top -
          (headerElement?.offsetHeight ?? 0) +
          (offset ?? 0),
        left: 0,
        behavior: scrollBehavior,
      });
    }
  }
};

export default scrollToPosition;
