let translateTimeout: NodeJS.Timeout;

sessionStorage.removeItem('missingTranslationsFound');
sessionStorage.removeItem('missingTranslationsReported');

export const translate = (key: string): string => {
  const lowerKey = key?.toLocaleLowerCase().trim();
  if (!window.__litium.translation[lowerKey]) {
    if (lowerKey) {
      if (!lowerKey.includes('.x')) {
        if (translateTimeout) {
          clearTimeout(translateTimeout);
        }

        const alreadyReported: string[] = JSON.parse(
          sessionStorage.getItem('missingTranslationsReported') || '[]'
        );

        const upForReport = [
          ...JSON.parse(
            sessionStorage.getItem('missingTranslationsFound') || '[]'
          ),
          key.trim(),
        ].filter((x) => !alreadyReported.includes(x));

        sessionStorage.setItem(
          'missingTranslationsFound',
          JSON.stringify(
            [...upForReport, key.trim()].filter(
              (value: string, index: number, self: string[]) => {
                return self.indexOf(value) === index;
              }
            )
          )
        );

        translateTimeout = setTimeout(() => {
          const items = JSON.parse(
            sessionStorage.getItem('missingTranslationsFound') || '[]'
          );
          if (items.length > 0) {
            sessionStorage.setItem(
              'missingTranslationsReported',
              JSON.stringify(
                [
                  ...JSON.parse(
                    sessionStorage.getItem('missingTranslationsReported') ||
                      '[]'
                  ),
                  ...JSON.parse(
                    sessionStorage.getItem('missingTranslationsFound') || '[]'
                  ),
                ].filter((value: string, index: number, self: string[]) => {
                  return self.indexOf(value) === index;
                })
              )
            );
            sessionStorage.removeItem('missingTranslationsFound');

            console.group('🔥 Missing translations found');
            items.forEach((element: string) => {
              console.info('js.' + element);
            });
            console.groupEnd();
          }
        }, 500);
      }
      const split = key.split('.');
      return split[split.length - 1];
    }
  }
  return window.__litium.translation[lowerKey] || key;
};
