import { combineReducers } from 'redux';
import { expandableSlice } from './Slices/expandableSlice';
import { quickSearchSlice } from './Slices/quickSearchSlice';
import { notificationSlice } from './Slices/notificationSlice';
import { cartSlice } from './Slices/cartSlice';
import { productSlice } from './Slices/productSlice';
import { myPagesSlice } from './Slices/myPagesSlice';
import { quickOrderSlice } from './Slices/quickOrderSlice';
import { checkoutSlice } from './Slices/checkoutSlice';
import { shoppingListSlice } from './Slices/shoppingListSlice';
import { facetedSearchSlice } from './Slices/facetedSearchSlice';
import { orderHistorySlice } from './Slices/orderHistorySlice';

const app = combineReducers({
  expandable: expandableSlice.reducer,
  quickSearch: quickSearchSlice.reducer,
  notification: notificationSlice.reducer,
  cart: cartSlice.reducer,
  checkout: checkoutSlice.reducer,
  product: productSlice.reducer,
  myPages: myPagesSlice.reducer,
  quickOrder: quickOrderSlice.reducer,
  shoppingList: shoppingListSlice.reducer,
  facetedSearch: facetedSearchSlice.reducer,
  orderHistory: orderHistorySlice.reducer,
});

export default app;
