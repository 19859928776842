import {
  createAsyncThunk,
  createSlice,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit';
import { post } from '../Services/http';
import {
  QuickSearchResult,
  QuickSearchState,
  Status,
} from '../Types/quickSearch';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';

const sliceName = 'quickSearch';
const rootRoute = '/api/quickSearch';
const initialState: QuickSearchState = {
  query: '',
  results: {
    products: [],
    pages: [],
    news: [],
    categories: [],
  },
  hasResults: false,
  showResult: false,
  selectedItem: -1,
  status: Status.Idle,
  searchUrl: window.__litium?.quickSearchUrl || '',
};

let abortController: AbortController;

export const searchQuery = createAsyncThunk<QuickSearchResult, string>(
  `${sliceName}/searchQuery`,
  async (queryString, { rejectWithValue, dispatch }) => {
    abortController && abortController.abort();
    abortController = new AbortController();

    try {
      if (!queryString) {
        dispatch(toggleResult(false));
        return { ...initialState.results };
      }

      const response = await post(rootRoute, queryString, abortController);
      return await response.json();
    } catch (err) {
      const hasErrResponse = (
        err as {
          response: { [key: string]: string };
        }
      ).response;
      if (!hasErrResponse) {
        throw err;
      }
      return rejectWithValue({ ...hasErrResponse });
    }
  }
);

export const quickSearchSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setResult: (state, action: PayloadAction<QuickSearchResult>) => {
      state.results = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string | null>) => {
      state.query = action.payload || '';
    },
    toggleResult: (state, action: PayloadAction<boolean>) => {
      state.showResult = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchQuery.pending, (state) => {
        state.showResult = true;
        state.status = Status.Loading;
      })
      .addCase(searchQuery.fulfilled, (state, action) => {
        state.results = action.payload;
        state.hasResults =
          [
            ...action.payload.products,
            ...action.payload.pages,
            ...action.payload.categories,
            ...action.payload.news,
          ].length > 0;
        state.showResult = state.query ? state.showResult : false;
        state.status = Status.Idle;
      })
      .addMatcher(isRejected, (state) => {
        state.status = Status.Idle;
      });
  },
});

export const { setSearchQuery, setResult, toggleResult } =
  quickSearchSlice.actions;

export default quickSearchSlice.reducer;
