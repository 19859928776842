import { unmountComponentAtNode } from 'react-dom';
import splideSliders from './Functions/splideSliders';

let abortController: AbortController;
let timeout: NodeJS.Timeout;

/**
 * Softly replace the content on the page with content fron url.
 * Will replace content in .main-content and meta tags in head.
 *
 * @param {string | null} url
 * @param {boolean} scrollTop
 * @param {string | undefined} element
 */
const softLoad = (
  url: string | null | undefined,
  scrollTop = true,
  element?: string,
): void => {
  abortController && abortController.abort();
  abortController = new AbortController();

  if (url && url !== window.location.href) {
    const mainContent = document.querySelector<HTMLElement>(mainContentQuery);
    const header = document.getElementById(headerId);

    if (!document.querySelector('.product-page-block')) {
      mainContent?.classList.add(mainContentLoadingClass);
    }

    if (scrollTop) {
      window.__litium.scrollToTop();
    }

    timeout = setTimeout(() => {
      if (mainContent) {
        mainContent.style.opacity = '0.5';
      }
    }, 600);

    fetch(url, { signal: abortController.signal })
      .then((response) => response.text())
      .then((data) => {
        const temp = document.createElement('html');
        temp.innerHTML = data;

        clearTimeout(timeout);
        if (mainContent) {
          mainContent.style.opacity = '';
        }

        if (element) {
          const tempElement = temp.querySelector<HTMLElement>(element);
          const currentElement = document.querySelector<HTMLElement>(element);
          if (tempElement && currentElement) {
            currentElement.innerHTML = tempElement.innerHTML;
          }
        } else {
          const tempMainContent =
            temp.querySelector<HTMLElement>(mainContentQuery);
          const tempHeader = temp.querySelector<HTMLElement>(`#${headerId}`);

          if (!document.querySelector('.product-page-block')) {
            Array.from(
              mainContent?.querySelectorAll(
                'div[id*="component"], div[id*="Component"], div[class*="component"]',
              ) || [],
            ).forEach((x) => {
              unmountComponentAtNode(x);
            });
          }

          if (mainContent && tempMainContent) {
            if (mainContent.querySelector('.product-page-block')) {
              [
                '.breadcrumbs',
                '.product-page-grid__images',
                '.product-page-grid__title',
                '.product-page-grid__details',
                '.product-page-grid__data',
                '.product-price-info',
              ].forEach((e) => {
                const element = mainContent.querySelector(e);
                const tempElement = tempMainContent.querySelector(e);
                if (element && tempElement) {
                  element.innerHTML = tempElement.innerHTML || '';
                }
              });
            } else {
              mainContent.classList.add(mainContentWillRemoveClass);
              mainContent.parentNode?.insertBefore(
                tempMainContent,
                mainContent.nextSibling,
              );
            }
          }

          // Update meta fields
          const metaFields = Array.from(
            document
              .getElementsByTagName('head')[0]
              .querySelectorAll('meta, title, script:not([src])'),
          );
          const tempMetaFields = Array.from(
            temp
              .getElementsByTagName('head')[0]
              .querySelectorAll('meta, title'),
          );
          tempMetaFields.forEach((elem) => {
            if (elem.tagName === 'SCRIPT') {
              const newScript = document.createElement('script');
              newScript.innerHTML = elem.innerHTML;
              document.getElementsByTagName('head')[0].appendChild(newScript);
            } else {
              document.getElementsByTagName('head')[0].appendChild(elem);
            }
          });

          metaFields.forEach((elem) => elem.remove());

          if (mainContent && tempMainContent) {
            mainContent.style.minHeight = `${Math.max(
              mainContent.offsetHeight,
              tempMainContent.offsetHeight,
            )}px`;
            tempMainContent.style.minHeight = `${Math.max(
              mainContent.offsetHeight,
              tempMainContent.offsetHeight,
            )}px`;
          }

          // Wait for images to load, then switch content
          const images = mainContent?.getElementsByTagName('img') || [];
          let imagesLoaded = 0;

          const imgload = () => {
            imagesLoaded++;
            if (images.length === 0 || imagesLoaded >= images.length) {
              if (header && tempHeader) {
                header.className = tempHeader.className;
              }
              if (tempMainContent) {
                tempMainContent.style.minHeight = '';
              }
              if (mainContent) {
                if (!mainContent.querySelector('.product-page-block')) {
                  mainContent.remove();
                }
              }
              temp.remove();

              // Bootstrap components to make React work for new content
              window.__litium.bootstrapComponents(mainContent);
            }
          };

          if (images.length === 0) {
            imgload();
          } else {
            Array.from(images).forEach((image) => {
              if (
                image.complete ||
                (image.offsetWidth > 0 && image.offsetHeight > 0)
              ) {
                imgload();
              } else {
                image.addEventListener('load', imgload);
              }
            });
          }
        }

        splideSliders();

        // Update url
        window.history.pushState({ softLoadUrl: url }, '', url);
      })
      .catch((e) => {
        console.error(e);
        mainContent?.classList.remove(mainContentLoadingClass);
        window.location.href = url;
      });
  }

  if (!backButtonEventHasBeenCreated) {
    backButtonEventHasBeenCreated = true;
    window.addEventListener('popstate', (popStateEvent) => {
      const url = (popStateEvent.target as Window).location.href;
      if (url) {
        window.location.href = url;
      }
    });
  }
};

let backButtonEventHasBeenCreated = false;

const headerId = 'header';
const mainContentQuery = '.main-content';
const mainContentLoadingClass = 'main-content--loading';
const mainContentWillRemoveClass = 'main-content--will-remove';

export default softLoad;
