import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import { get, post, put, remove } from '../Services/http';
import {
  Address,
  MyPagesState,
  Organization,
  PageData,
  PageLink,
  Seller,
  UserAccount,
} from '../Types/myPages';
import { RootState } from '../store';
import scrollToTop from '../Functions/scrollToTop';
import { errorResponse } from '../errorResponse';

const sliceName = 'myPages';
const rootRoute = '/api/myPages';
const initialState: MyPagesState = {
  errors: {},
  pages: [],
  openModule: null,
  ...((window.__litium.preloadState?.myPages || {}) as Partial<MyPagesState>),
};

let abortController: AbortController;

/***
 * Get user account.
 */
export const getAccount = createAsyncThunk<UserAccount>(
  `${sliceName}/getAccount`,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await get(`${rootRoute}/account`, abortController);
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'account'));
    }
  }
);

/***
 * Update user account.
 */
export const updateAccount = createAsyncThunk<
  UserAccount,
  Partial<UserAccount>
>(`${sliceName}/updateAccount`, async (data, { rejectWithValue }) => {
  try {
    const response = await put(`${rootRoute}/account`, data, abortController);
    return await response.json();
  } catch (err) {
    return rejectWithValue(await errorResponse(err, 'account'));
  }
});

/***
 * Get organization.
 * Will get user account if needed.
 */
export const getOrganization = createAsyncThunk<Organization>(
  `${sliceName}/getOrganization`,
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const account = (getState() as RootState).myPages.account;
      !account && (await dispatch(getAccount()));

      const response = await get(`${rootRoute}/organization`, abortController);
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'organization'));
    }
  }
);

/***
 * Update organization
 */
export const updateOrganization = createAsyncThunk<
  Organization,
  Partial<Organization>
>(
  `${sliceName}/updateOrganization`,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await put(
        `${rootRoute}/organization`,
        data,
        abortController
      );
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'organization'));
    }
  }
);

/***
 * Get seller.
 */
export const getSeller = createAsyncThunk<Seller>(
  `${sliceName}/getSeller`,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await get(`${rootRoute}/seller`, abortController);
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'seller'));
    }
  }
);

/***
 * Get list of pages for navigation.
 */
export const getPages = createAsyncThunk<PageLink[]>(
  `${sliceName}/getPages`,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await get(`${rootRoute}/pages`, abortController);
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'page'));
    }
  }
);

/***
 * Set page and update url.
 */
export const setPage = createAsyncThunk<PageData, PageLink>(
  `${sliceName}/setPage`,
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const currentPageData = (getState() as RootState).myPages.pageData;

      if (!data.systemId && data.href) {
        window.location.href = data.href;
        return {};
      }

      if (currentPageData?.name && data?.text) {
        document.title = document.title.replace(
          currentPageData.name,
          data.text
        );
      }

      history.pushState(null, '', data.href);

      const response = await get(
        `${rootRoute}/page/${data.systemId}`,
        abortController
      );
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'page'));
    }
  }
);

/***
 * Update address.
 */
export const updateAddress = createAsyncThunk<_, Address>(
  `${sliceName}/updateAddress`,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = data.systemId
        ? await put(`${rootRoute}/address`, data, abortController)
        : await post(`${rootRoute}/address`, data, abortController);
      dispatch(getOrganization());
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'address'));
    }
  }
);

/***
 * Delete address.
 */
export const deleteAddress = createAsyncThunk<_, Address>(
  `${sliceName}/deleteAddress`,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await remove(`${rootRoute}/address`, data.systemId);
      dispatch(getOrganization());
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'address'));
    }
  }
);

/***
 * Get list of persons for organization.
 * Get organization if needed.
 */
export const getPersons = createAsyncThunk<UserAccount[]>(
  `${sliceName}/getPersons`,
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const organization = (getState() as RootState).myPages.organization;
      !organization && (await dispatch(getOrganization()));

      const response = await get(`${rootRoute}/person`, abortController);
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'person'));
    }
  }
);

/***
 * Update person.
 */
export const updatePerson = createAsyncThunk<_, Address>(
  `${sliceName}/updatePerson`,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = data.systemId
        ? await put(`${rootRoute}/person`, data, abortController)
        : await post(`${rootRoute}/person`, data, abortController);
      dispatch(getPersons());
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'person'));
    }
  }
);

/***
 * Delete person.
 */
export const deletePerson = createAsyncThunk<_, Address>(
  `${sliceName}/deletePerson`,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await remove(`${rootRoute}/person`, data.systemId);
      dispatch(getPersons());
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'person'));
    }
  }
);

export const myPagesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setOpenModule: (state, action: PayloadAction<string | undefined>) => {
      state.openModule = action.payload;
    },
    clearError: (state, action: PayloadAction<string>) => {
      delete state.errors[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccount.fulfilled, (state, action) => {
        state.account = action.payload;
      })
      .addCase(updateAccount.fulfilled, (state, action) => {
        state.account = action.payload;
      })
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.organization = action.payload;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.organization = action.payload;
      })
      .addCase(getSeller.fulfilled, (state, action) => {
        state.seller = action.payload;
      })
      .addCase(getPages.fulfilled, (state, action) => {
        state.pages = action.payload;
      })
      .addCase(setPage.pending, (state, action) => {
        state.errors = {};
        state.openModule = null;

        scrollToTop();
      })
      .addCase(setPage.fulfilled, (state, action) => {
        state.pageData = action.payload;
        state.pages = state.pages.map((page) => ({
          ...page,
          isCurrent: action.payload.systemId === page.systemId,
        }));
      })
      .addCase(getPersons.fulfilled, (state, action) => {
        state.persons = action.payload;
      })
      .addMatcher(isRejectedWithValue, (state, action) => {
        state.errors = {
          ...state.errors,
          ...(action.payload as {
            [key: string]: { [key: string]: string };
          }),
        };
      });
  },
});

export const { setOpenModule, clearError } = myPagesSlice.actions;

export default myPagesSlice.reducer;
