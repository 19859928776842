import { Address } from './myPages';

export type CheckoutState = {
  deliveryMethods: DeliveryMethod[];
  selectedDeliveryMethod?: DeliveryMethod;
  paymentMethods: PaymentMethod[];
  selectedPaymentMethod?: DeliveryMethod;
  companyAddresses: Address[];
  deliveryAddresses: Address[];
  companyName?: string;
  customerDetails: CustomerDetails;
  selectedCompanyAddressId?: string;
  selectedDeliveryAddressId?: string;
  customDeliveryAddress?: Address;
  authenticated: boolean;
  acceptTermsOfCondition: boolean;
  showAlternativeAddress: boolean;
  checkoutMode: CheckoutMode;
  signUp: boolean;
  isBusinessCustomer: boolean;
  success: boolean;
  errorMessages: { [key: string]: string };
  checkoutUrl?: string;
  loginUrl?: string;
  paymentWidget?: {
    responseString: string;
    redirectUrl: string;
    id: string;
    _force_update: string;
  };
  discountCode?: string;
  usedDiscountCodes: string[];
  redirectUrl?: string;

  // Modules
  openModule: CheckoutModuleEnum;

  // Additional order info
  additionalOrderInfo: AdditionalOrderInfo;
};

export type CustomerDetails = {
  country?: string;
  systemId?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};

export type AdditionalOrderInfo = {
  purchaseNumber?: string;
};

export type DeliveryMethod = {
  id: string;
  name: string;
  price?: number;
  formattedPrice?: string;
};

export type PaymentMethod = {
  id: string;
  name: string;
  price?: number;
  formattedPrice: string;
};

export enum CheckoutMode {
  Both = 1,
  PrivateCustomers = 2,
  CompanyCustomers = 4,
}

export enum CheckoutModuleEnum {
  CustomerInfo = 1,
  DeliveryInfo = 2,
  PaymentInfo = 3,
}

export type ExtractScriptsFunction = (domString: string) => {
  scriptFiles: string[];
  html: string;
  scripts: string[];
};

export type ExecuteScriptFunction = (
  domId: string,
  scriptContent: string
) => void;

export type IncludeScriptFunction = (domId: string, scriptUrl: string) => void;

export type PaymentSession = any;
