import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from '../Services/http';
import { RootState } from '../store';
import {
  FacetedSearchState,
  Filter,
  FilterOption,
  NavigationTheme,
  SortParameters,
  Status,
} from '../Types/facetedSearch';

const propertyFromUrl = (propertyName: string) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(propertyName);
};

const sliceName = 'facetedSearch';
const rootRoute = '/api/productFilter';
const initialState: FacetedSearchState = {
  sortCriteria: {
    sortItems: [],
    start: 1,
    end: 0,
    totalHits: 0,
  },
  facetFilters: [],
  navigationTheme: NavigationTheme.Filter,
  productsViewCachedId: undefined,
  sortOrder: propertyFromUrl(SortParameters.SortBy),
  sortDirection: propertyFromUrl(SortParameters.SortDirection),
  status: Status.Idle,
  ...((window.__litium.preloadState.facetedSearch ||
    {}) as Partial<FacetedSearchState>),
};

let abortController: AbortController;

export const query = createAsyncThunk<
  FacetedSearchState,
  {
    queryString?: string;
    withHtmlResult?: boolean;
    productsViewCachedId?: string;
    clearSorting?: true;
    clearInStock?: true;
  }
>(
  `${sliceName}/query`,
  async (
    {
      queryString = '',
      withHtmlResult = true,
      productsViewCachedId = new Date().getTime().toString(),
      clearSorting,
      clearInStock,
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    const state = (getState() as RootState).facetedSearch;

    if (withHtmlResult) {
      dispatch(setStatus(Status.Loading));
    }

    const params = new URLSearchParams(queryString);

    params.delete('page');

    if (!clearSorting) {
      if (state.sortOrder === undefined) {
        dispatch(
          setSortOrder({
            sortOrder: params.get(SortParameters.SortBy),
            sortDirection: params.get(SortParameters.SortDirection),
          })
        );
      } else {
        if (state.sortOrder) {
          params.set(SortParameters.SortBy, state.sortOrder);
        } else {
          params.delete(SortParameters.SortBy);
        }

        if (state.sortDirection) {
          params.set(SortParameters.SortDirection, state.sortDirection);
        } else {
          params.delete(SortParameters.SortDirection);
        }
      }
    }

    if (!clearInStock) {
      if (state.inStock) {
        params.set(SortParameters.InStock, state.inStock.toString());
      } else {
        params.delete(SortParameters.InStock);
      }
    }

    let url = withHtmlResult ? `${rootRoute}/withHtmlResult` : rootRoute;
    if (params.toString()?.trim()) {
      url += `?${params.toString()}`;
    }

    abortController && abortController.abort();
    abortController = new AbortController();

    try {
      const browserUrl =  window.location.href;
      
      window.history.pushState('search', 'Search Page', browserUrl);

      const response = await get(url, abortController);
      const result: FacetedSearchState = response.json();

      if (withHtmlResult) {
        window.__litium.cache = window.__litium.cache || {};
        window.__litium.cache[`${sliceName}/query`] = {
          productsViewCachedId,
        };
      }

      return result;
    } catch (err) {
      const hasErrResponse = (err as { response: { [key: string]: string } })
        .response;
      if (!hasErrResponse) {
        throw err;
      }
      return rejectWithValue({ ...hasErrResponse });
    }
  }
);

export const submit = createAsyncThunk<void, Filter[]>(
  `${sliceName}/submit`,
  async (facetFilters, { dispatch }) => {
    const filterCriteria = toFilterCriteria(facetFilters);
    const filterIds = facetFilters.map((filter) => filter.id);
    const ignoredParams = ['page'];
    const unChangedParams = (window.location.search.substring(1) || '')
      .split('&')
      .filter((param) => {
        const [id] = param.split('=');
        return (
          param.length > 0 &&
          !filterIds.includes(id) &&
          !ignoredParams.includes(id)
        );
      });
    const queryString = [...unChangedParams, ...filterCriteria].join('&');
    dispatch(query({ queryString }));
  }
);

export const searchFacetChange = createAsyncThunk<
  void,
  { filter: Filter; option: FilterOption; autoSubmit?: boolean }
>(
  `${sliceName}/searchFacetChange`,
  async ({ filter, option, autoSubmit = true }, { dispatch, getState }) => {
    if (filter) {
      const allFilters = (getState() as RootState).facetedSearch.facetFilters;
      const newFilters = updateFilterOption(allFilters, filter, option);
      dispatch(setFilters(newFilters));

      if (autoSubmit) {
        dispatch(submit(newFilters));
      }
    }
  }
);

export const updateFilterOption = (
  allFilters: Filter[] = [],
  filter: Filter | null = null,
  option: FilterOption | null = null
): Filter[] => {
  if (!filter) return allFilters;
  const filterIndex = allFilters.findIndex((f) => f.id === filter.id);
  const newFilter = option
    ? toggleFilterValue(filter, option)
    : { ...filter, selectedOptions: [] };
  return [
    ...allFilters.slice(0, filterIndex),
    newFilter,
    ...allFilters.slice(filterIndex + 1),
  ];
};

const toggleFilterValue = (filter: Filter, option: FilterOption) => {
  const { singleSelect, selectedOptions } = filter;
  const optionIndex = selectedOptions.indexOf(option.id);
  const newSelectedOptions =
    optionIndex < 0
      ? singleSelect
        ? [option.id]
        : [...selectedOptions, option.id]
      : [
          ...selectedOptions.slice(0, optionIndex),
          ...selectedOptions.slice(optionIndex + 1),
        ];
  return { ...filter, selectedOptions: newSelectedOptions };
};

const toFilterCriteria = (groups: Filter[]) =>
  groups
    .map((group) =>
      group.selectedOptions
        .filter((val) => val && val.length > 0)
        .map(
          (val) => `${encodeURIComponent(group.id)}=${encodeURIComponent(val)}`
        )
    )
    .reduce((flat, current) => [...flat, ...current], []);

export const facetedSearchSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<Status>) => {
      state.status = action.payload;
    },
    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.facetFilters = action.payload;
    },
    setSortOrder: (
      state,
      action: PayloadAction<{
        sortOrder: string | null | undefined;
        sortDirection: string | null | undefined;
      }>
    ) => ({
      ...state,
      ...action.payload,
    }),
    setInStock: (state, action: PayloadAction<boolean>) => ({
      ...state,
      inStock: action.payload,
    }),
    clearFilters: (state) => {
      state.facetFilters = state.facetFilters.map((x) => {
        x.selectedOptions = [];
        return x;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(query.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      sortCriteria:
        (action.payload?.sortCriteria.sortItems || []).length > 0
          ? action.payload.sortCriteria
          : state.sortCriteria,
      subNavigation: action.payload.subNavigation ?? state.subNavigation,
      status: Status.Idle,
    }));
  },
});

export const { setFilters, setStatus, clearFilters, setSortOrder, setInStock } =
  facetedSearchSlice.actions;

export default facetedSearchSlice.reducer;
