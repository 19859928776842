import React, { lazy, Suspense } from 'react';
import { createRoot, Root } from 'react-dom/client';
import smoothscroll from 'smoothscroll-polyfill';
import store from './store';
import { bindActionCreators } from 'redux';
// @ts-ignore
import { Fancybox } from '@fancyapps/ui';
import { Provider } from 'react-redux';
import { IconEnum } from './Components/Shared/Icon';
import softLoad from './softLoad';
import scrollToTop from './Functions/scrollToTop';
import scrollToPosition from './Functions/scrollToPosition';
import toggleHeaderClass from './Functions/toggleHeaderClass';
import toggleLanguageSelector from './Functions/toggleLanguageSelector';
import headerScrollClass from './Functions/headerScrollClass';
import { mainNavigationHandler } from './Functions/mainNavigationHandler';
import splideSliders from './Functions/splideSliders';
import { toggleMiniCart } from './Slices/cartSlice';
import {updateMetaTag} from "./Functions/updateMetaTag";

Fancybox.bind('[data-fancybox]');

smoothscroll.polyfill();

window.__litium = {
  ...window.__litium,
  softLoad,
  scrollToTop,
  scrollToPosition,
  toggleHeaderClass,
  toggleLanguageSelector,
  reduxActions: bindActionCreators({ toggleMiniCart }, store.dispatch),
  bootstrapComponents: () => bootstrapComponents(),
  cache: {},
};

let quickSearchRoot: Root | undefined;
let miniCartRoot: Root | undefined;
let cartRoot: Root | undefined;
let checkoutRoot: Root | undefined;
let globalNotificationRoot: Root | undefined;
let facetedSearchRoot: Root | undefined;
let facetedSearchBarRoot: Root | undefined;
let myPagesRoot: Root | undefined;
let quickOrderRoot: Root | undefined;
let quickOrderSearchRoot: Root | undefined;
let registrationFormRoot: Root | undefined;
let variantSelectorRoot: Root | undefined;
let addToCartRoot: Root | undefined;

const bootstrapComponents = (parentElement?: HTMLElement | Document) => {
  parentElement = parentElement || document;

  // EXPANDABLE
  const expandableElems = parentElement.querySelectorAll<HTMLDivElement>(
    '.expandable-component',
  );
  if (expandableElems && expandableElems?.length > 0) {
    const Expandable = lazy(() => import('./Components/Shared/Expandable'));
    Array.from(expandableElems).forEach((element) => {
      const { title = '', icon, groupId, open } = element.dataset;
      const component = (
        <Suspense>
          <Provider store={store}>
            <Expandable
              title={title}
              icon={icon as IconEnum | undefined}
              groupId={groupId}
              open={JSON.parse(open || 'false')}>
              {element.children}
            </Expandable>
          </Provider>
        </Suspense>
      );
      const tempElement = document.createElement('div');
      createRoot(tempElement).render(component);
      element.replaceWith(tempElement);
    });
  }

  // QUICK SEARCH
  const quickSearchElement = parentElement.querySelector('#QuickSearch');
  if (quickSearchElement && quickSearchElement.children.length === 0) {
    const QuickSearch = lazy(
      () => import('./Components/QuickSearch/QuickSearch'),
    );
    quickSearchRoot = quickSearchRoot || createRoot(quickSearchElement);
    quickSearchRoot.render(
      <Suspense>
        <Provider store={store}>
          <QuickSearch />
        </Provider>
      </Suspense>,
    );
  }

  // CART
  const cartElement = parentElement.querySelector('#Cart');
  if (cartElement) {
    const Cart = lazy(() => import('./Components/Cart/Cart'));
    cartRoot = cartRoot || createRoot(cartElement);
    cartRoot.render(
      <Suspense>
        <Provider store={store}>
          <Cart />
        </Provider>
      </Suspense>,
    );
  }

  // MINI CART
  const miniCartElement = parentElement.querySelector('#MiniCart');
  if (miniCartElement) {
    const MiniCart = lazy(() => import('./Components/MiniCart/MiniCart'));
    miniCartRoot = miniCartRoot || createRoot(miniCartElement);
    miniCartRoot.render(
      <Suspense>
        <Provider store={store}>
          <MiniCart />
        </Provider>
      </Suspense>,
    );
  }

  // CART QUANTITY
  const cartQuantityElements = document.getElementsByClassName(
    'cart-quantity-component',
  );
  if (cartQuantityElements && cartQuantityElements.length > 0) {
    const CartQuantity = lazy(() => import('./Components/Cart/CartQuantity'));
    Array.from(cartQuantityElements).forEach((element) => {
      createRoot(element).render(
        <Suspense>
          <Provider store={store}>
            <CartQuantity
              className={(element as HTMLElement).dataset['className']}
            />
          </Provider>
        </Suspense>,
      );
      element.classList.remove('cart-quantity-component');
    });
  }

  // CHECKOUT
  const checkoutElement = parentElement.querySelector('#Checkout');
  if (checkoutElement) {
    const Checkout = lazy(() => import('./Components/Checkout/Checkout'));
    checkoutRoot = checkoutRoot || createRoot(checkoutElement);
    checkoutRoot.render(
      <Suspense>
        <Provider store={store}>
          <Checkout />
        </Provider>
      </Suspense>,
    );
  }

  // MY PAGES
  const myPagesElement = parentElement.querySelector('#MyPages');
  if (myPagesElement) {
    const MyPages = lazy(() => import('./Components/MyPages/MyPages'));
    myPagesRoot = myPagesRoot || createRoot(myPagesElement);
    myPagesRoot.render(
      <Suspense>
        <Provider store={store}>
          <MyPages />
        </Provider>
      </Suspense>,
    );
  }

  // GLOBAL NOTIFICATION
  const globalNotificationElem = parentElement.querySelector(
    '#globalNotification',
  );
  if (globalNotificationElem) {
    const Notification = lazy(() => import('./Components/Shared/Notification'));
    globalNotificationRoot =
      globalNotificationRoot || createRoot(globalNotificationElem);
    globalNotificationRoot.render(
      <Suspense>
        <Provider store={store}>
          <Notification />
        </Provider>
      </Suspense>,
    );
  }

  // Variant selector
  const variantSelectorElement =
    parentElement.querySelector<HTMLElement>('#variantSelector');
  if (variantSelectorElement) {
    const VariantSelector = lazy(
      () => import('./Components/Product/VariantSelector'),
    );
    variantSelectorRoot =
      variantSelectorRoot || createRoot(variantSelectorElement);
    variantSelectorRoot.render(
      <Suspense>
        <Provider store={store}>
          <VariantSelector />
        </Provider>
      </Suspense>,
    );
  }

  // ADD TO CART
  const addToCartElement =
    parentElement.querySelector<HTMLElement>('#addToCart');
  if (addToCartElement) {
    const AddToCart = lazy(() => import('./Components/Product/AddToCart'));
    addToCartRoot = addToCartRoot || createRoot(addToCartElement);
    addToCartRoot.render(
      <Suspense>
        <Provider store={store}>
          <AddToCart
            inStock={JSON.parse(addToCartElement.dataset?.inStock || 'false')}
          />
        </Provider>
      </Suspense>,
    );
  }

  // QUICK ORDER
  const quickOrderElement = parentElement.querySelector('#QuickOrder');
  if (quickOrderElement) {
    const QuickOrder = lazy(() => import('./Components/QuickOrder/QuickOrder'));
    quickOrderRoot = quickOrderRoot || createRoot(quickOrderElement);
    quickOrderRoot.render(
      <Suspense>
        <Provider store={store}>
          <QuickOrder />
        </Provider>
      </Suspense>,
    );
  }

  // QUICK ORDER SEARCH
  const quickOrderSearchElement =
    parentElement.querySelector('#QuickOrderSearch');
  if (quickOrderSearchElement) {
    const QuickOrderSearch = lazy(
      () => import('./Components/QuickOrder/QuickOrderSearch'),
    );
    quickOrderSearchRoot =
      quickOrderSearchRoot || createRoot(quickOrderSearchElement);
    quickOrderSearchRoot.render(
      <Suspense>
        <Provider store={store}>
          <QuickOrderSearch />
        </Provider>
      </Suspense>,
    );
  }

  // FACETED SEARCH
  const facetedSearchElement = parentElement.querySelector('#FacetedSearch');
  if (facetedSearchElement && facetedSearchElement.children.length === 0) {
    const FacetedSearch = lazy(
      () => import('./Components/FacetedSearch/FacetedSearch'),
    );
    facetedSearchRoot = facetedSearchRoot || createRoot(facetedSearchElement);
    facetedSearchRoot.render(
      <Suspense>
        <Provider store={store}>
          <FacetedSearch />
        </Provider>
      </Suspense>,
    );
  }

  // FACETED SEARCH BAR
  const facetedSearchBarElement =
    parentElement.querySelector('#FacetedSearchBar');
  if (facetedSearchBarElement) {
    const FacetedSearchBar = lazy(
      () => import('./Components/FacetedSearch/FacetedSearchBar'),
    );
    facetedSearchBarRoot =
      facetedSearchBarRoot || createRoot(facetedSearchBarElement);
    facetedSearchBarRoot.render(
      <Suspense>
        <Provider store={store}>
          <FacetedSearchBar />
        </Provider>
      </Suspense>,
    );
  }

  // REGISTRATION FORM
  const registrationFormElement =
    parentElement.querySelector<HTMLElement>('#RegistrationForm');
  if (registrationFormElement) {
    const RegistrationForm = lazy(
      () => import('./Components/RegistrationForm/RegistrationForm'),
    );
    registrationFormRoot =
      facetedSearchBarRoot ?? createRoot(registrationFormElement);
    registrationFormRoot.render(
      <Suspense>
        <Provider store={store}>
          <RegistrationForm blockId={registrationFormElement.dataset.blockId} />
        </Provider>
      </Suspense>,
    );
  }
};

bootstrapComponents();
headerScrollClass(60);
mainNavigationHandler();
splideSliders();
updateMetaTag();
