const headerElement = document.getElementById('header');

const toggleHeaderClass = (className: string, force?: boolean) => {
  if (className && headerElement) {
    if (className.startsWith('--')) {
      headerElement.classList.toggle(
        `${headerElement.classList[0]}${className}`,
        force
      );
    } else {
      headerElement.classList.toggle(className, force);
    }
  }
};

export default toggleHeaderClass;
