import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit';
import { get, post, put } from '../Services/http';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import { AddToCartModel, CartState } from '../Types/cart';
import { showNotification } from './notificationSlice';
import { translate } from '../Services/translation';
import { errorResponse } from '../errorResponse';

const sliceName = 'cart';
const rootRoute = '/api/cart';
const initialState: CartState = {
  orderTotal: '',
  quantity: '',
  orderRows: [],
  discount: '',
  deliveryCost: '',
  paymentCost: '',
  grandTotal: '',
  vat: '',
  miniCartIsVisible: false,
  errors: {},
  ...((window.__litium.preloadState?.cart || {}) as Partial<CartState>),
};

let abortController: AbortController;

export const refreshCart = createAsyncThunk<CartState>(
  `${sliceName}/refreshCart`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await get(rootRoute);
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'cart'));
    }
  }
);

export const addToCart = createAsyncThunk<CartState, AddToCartModel>(
  `${sliceName}/addToCart`,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await post(`${rootRoute}/add`, data, abortController);

      dispatch(
        showNotification({
          text: translate('cart.notification.addedToCart'),
          timeout: 2000,
        })
      );

      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'cart'));
    }
  }
);

export const updateRow = createAsyncThunk<CartState, AddToCartModel>(
  `${sliceName}/updateRow`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await put(`${rootRoute}/update`, data);
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'cart'));
    }
  }
);

export const getCart = createAsyncThunk(
  `${sliceName}/getCart`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await get(rootRoute);
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'cart'));
    }
  }
);

export const reorder = createAsyncThunk<void, string>(
  `${sliceName}/reorder`,
  async (orderId, { rejectWithValue, dispatch }) => {
    try {
      const response = await post(`${rootRoute}/reorder`, { orderId });

      dispatch(getCart());
      dispatch(
        showNotification({
          text: translate('cart.notification.reorderAddedToCart'),
          timeout: 2000,
        })
      );

      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, 'cart'));
    }
  }
);

export const cartSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    toggleMiniCart: (state, action: PayloadAction<boolean | undefined>) => ({
      ...state,
      miniCartIsVisible:
        action.payload === undefined
          ? !state.miniCartIsVisible
          : action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToCart.pending, (state) => ({
        ...state,
      }))
      .addCase(addToCart.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }))
      .addCase(updateRow.pending, (state) => ({
        ...state,
      }))
      .addCase(updateRow.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }))
      .addCase(getCart.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }))
      .addMatcher(isRejectedWithValue, (state, action) => {
        state.errors = {
          ...state.errors,
          ...(action.payload as {
            [key: string]: { [key: string]: string };
          }),
        };
      });
  },
});

export const { toggleMiniCart } = cartSlice.actions;

export default cartSlice.reducer;
