export function updateMetaTag() {
  if (window.location.search.includes('?sort_by=') || window.location.search.includes('&sort_by=')) {
    let meta = document.querySelector('meta[name="robots"]') as HTMLMetaElement;
    if (!meta) {
      meta = document.createElement('meta') as HTMLMetaElement;
      meta.name = "robots";
      document.head.appendChild(meta);
    }
    meta.content = "noindex, follow";
  } 
  else {
    let meta = document.querySelector('meta[name="robots"]') as HTMLMetaElement;
    if (meta) {
      meta.content = "index, follow";
    }
  }
}

document.addEventListener('DOMContentLoaded', updateMetaTag);

const observer = new MutationObserver(updateMetaTag);
observer.observe(document.body, { childList: true, subtree: true });

const originalPushState = history.pushState;
history.pushState = function(...args) {
  originalPushState.apply(history, args);
  updateMetaTag();
};
const originalReplaceState = history.replaceState;
history.replaceState = function(...args) {
  originalReplaceState.apply(history, args);
  updateMetaTag();
};
