import Splide from '@splidejs/splide';

/**
 * Initiate Splide Sliders
 */
const splideSliders = () => {
  productPageSlider();
};

const productPageSlider = () => {
  const sliderElement = document.querySelector('#productImageSplide');
  if (!sliderElement) return;

  const slider = new Splide(sliderElement as HTMLElement, {
    type: 'fade',
    rewind: true,
    pagination: false,
    breakpoints: {
      600: {
        pagination: true,
        arrows: false,
        drag: true,
      },
    },
  }).mount();

  let current: Element;

  const thumbnails = document.querySelectorAll('#productThumbsSplide > img');
  if (thumbnails.length > 0) {
    Array.from(thumbnails).map((element, index) =>
      element.addEventListener('click', () => slider.go(index))
    );

    current = thumbnails[0];
    current.classList.add(`${current.classList[0]}--active`);

    slider.on('mounted move', function () {
      const thumbnail = thumbnails[slider.index];

      if (thumbnail) {
        if (current) {
          current.classList.remove(`${thumbnail.classList[0]}--active`);
        }

        thumbnail.classList.add(`${thumbnail.classList[0]}--active`);
        current = thumbnail;
      }
    });
  }
};

export default splideSliders;
