import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit';
import { get, post, put, remove } from '../Services/http';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import { showNotification } from './notificationSlice';
import { translate } from '../Services/translation';
import { ShoppingList, ShoppingListState } from '../Types/shoppingList';
import { errorResponse } from '../errorResponse';
import { getCart } from './cartSlice';

const sliceName = 'shoppingList';
const rootRoute = '/api/shopping-list';
const initialState: ShoppingListState = {
  errors: {},
  lists: [],
};

let abortController: AbortController;

export const getLists = createAsyncThunk<ShoppingList[]>(
  `${sliceName}/getLists`,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await get(
        `${rootRoute}?excludeRows=true`,
        abortController
      );
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, ''));
    }
  }
);

export const getList = createAsyncThunk<ShoppingList, string>(
  `${sliceName}/getList`,
  async (systemId, { rejectWithValue, dispatch }) => {
    try {
      const response = await get(`${rootRoute}/${systemId}`, abortController);
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, ''));
    }
  }
);

export const createList = createAsyncThunk<ShoppingList, { name: string }>(
  `${sliceName}/createList`,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await post(rootRoute, data, abortController);
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, ''));
    }
  }
);

export const editList = createAsyncThunk<
  ShoppingList,
  { systemId: string; name: string }
>(`${sliceName}/editList`, async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await put(
      `${rootRoute}/${data.systemId}`,
      data,
      abortController
    );
    return await response.json();
  } catch (err) {
    return rejectWithValue(await errorResponse(err, ''));
  }
});

export const deleteList = createAsyncThunk<boolean, string>(
  `${sliceName}/deleteList`,
  async (systemId, { rejectWithValue, dispatch }) => {
    try {
      const response = await remove(`${rootRoute}/${systemId}`, {});
      dispatch(setActiveList(undefined));
      dispatch(getLists());
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, ''));
    }
  }
);

export const addRow = createAsyncThunk<
  ShoppingList,
  { listSystemId: string; articleNumber: string; quantity: number }
>(`${sliceName}/addRow`, async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await post(
      `${rootRoute}/${data.listSystemId}/row`,
      data,
      abortController
    );

    dispatch(
      showNotification({
        text: translate('shoppingList.notification.addedToShoppingList'),
        timeout: 2000,
      })
    );

    return await response.json();
  } catch (err) {
    return rejectWithValue(await errorResponse(err, ''));
  }
});

export const deleteRow = createAsyncThunk<
  boolean,
  { listSystemId: string; rowSystemId: string }
>(`${sliceName}/deleteRow`, async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await remove(
      `${rootRoute}/${data.listSystemId}/row/${data.rowSystemId}`,
      data
    );
    await dispatch(getList(data.listSystemId));
    return await response;
  } catch (err) {
    return rejectWithValue(await errorResponse(err, ''));
  }
});

export const editRow = createAsyncThunk<
  ShoppingList,
  {
    listSystemId: string;
    rowSystemId: string;
    quantity: number;
  }
>(`${sliceName}/editRow`, async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await put(
      `${rootRoute}/${data.listSystemId}/row/${data.rowSystemId}`,
      data,
      abortController
    );
    return await response.json();
  } catch (err) {
    return rejectWithValue(await errorResponse(err, ''));
  }
});

export const addListToCart = createAsyncThunk<void, string>(
  `${sliceName}/addListToCart`,
  async (listSystemId, { rejectWithValue, dispatch }) => {
    try {
      const response = await post(`${rootRoute}/${listSystemId}/cart`, {});
      dispatch(getCart());
      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, ''));
    }
  }
);

export const addRowsFromCart = createAsyncThunk<ShoppingList, string>(
  `${sliceName}/addRowsFromCart`,
  async (listSystemId, { rejectWithValue, dispatch }) => {
    try {
      const response = await post(
        `${rootRoute}/${listSystemId}/addRowsFromCart`,
        {},
        abortController
      );

      dispatch(
        showNotification({
          text: translate('shoppingList.notification.addedToShoppingList'),
          timeout: 2000,
        })
      );

      return await response.json();
    } catch (err) {
      return rejectWithValue(await errorResponse(err, ''));
    }
  }
);

export const shoppingListSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: (state) => ({ ...initialState }),
    setActiveList: (state, action: PayloadAction<ShoppingList | undefined>) => {
      state.activeList = action.payload;

      const urlParams = new URLSearchParams(window.location.search);

      action.payload?.systemId
        ? urlParams.set('id', action.payload?.systemId)
        : urlParams.delete('id');

      window.history.pushState(
        null,
        '',
        urlParams.toString()
          ? `?${urlParams.toString()}`
          : window.location.pathname
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLists.fulfilled, (state, action) => {
        state.lists = action.payload;
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.activeList = action.payload;
      })
      .addCase(createList.fulfilled, (state, action) => {
        state.lists = [...(state.lists || []), action.payload];
        state.activeList = action.payload;
      })
      .addCase(editList.fulfilled, (state, action) => {
        const currentIndex = state.lists?.findIndex(
          (x) => x.systemId === action.payload.systemId
        );
        if (state.lists && currentIndex !== undefined) {
          state.lists[currentIndex] = action.payload;
        }
        state.activeList = action.payload;
      })
      .addCase(addRow.fulfilled, (state, action) => {
        const currentIndex = state.lists?.findIndex(
          (x) => x.systemId === action.payload.systemId
        );
        if (state.lists && currentIndex !== undefined) {
          state.lists[currentIndex] = action.payload;
        }
        state.activeList = action.payload;
      })
      .addCase(editRow.fulfilled, (state, action) => {
        const currentIndex = state.lists?.findIndex(
          (x) => x.systemId === action.payload.systemId
        );
        if (state.lists && currentIndex !== undefined) {
          state.lists[currentIndex] = action.payload;
        }
        state.activeList = action.payload;
      })
      .addMatcher(isRejectedWithValue, (state, action) => {
        state.errors = {
          ...state.errors,
          ...(action.payload as {
            [key: string]: { [key: string]: string };
          }),
        };
      });
  },
});

export const { reset, setActiveList } = shoppingListSlice.actions;

export default shoppingListSlice.reducer;
