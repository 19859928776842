import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get, post, put } from '../Services/http';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import { AddToCartModel, CartState } from '../Types/cart';
import { showNotification } from './notificationSlice';
import { translate } from '../Services/translation';
import { addToCart, updateRow } from './cartSlice';
import {
  QuickOrderInstance,
  QuickOrderProduct,
  QuickOrderState,
} from '../Types/quickOrder';
import { ShoppingList } from '../Types/shoppingList';

const sliceName = 'quickOrder';
const rootRoute = '/api/quickOrder';
const initialState: QuickOrderState = {
  query: '',
  products: [],
  chosenShoppingList: '',
  ...((window.__litium.preloadState?.quickOrder ||
    {}) as Partial<QuickOrderState>),
};

let abortController: AbortController;

export const getProducts = createAsyncThunk<QuickOrderProduct[], string>(
  `${sliceName}/getProducts`,
  async (query, { rejectWithValue, dispatch }) => {
    try {
      if (!query) {
        return [];
      }

      const response = await get(
        `${rootRoute}?query=${query}`,
        abortController
      );
      return await response.json();
    } catch (err) {
      const hasErrResponse = (
        err as {
          response: { [key: string]: string };
        }
      ).response;
      if (!hasErrResponse) {
        throw err;
      }
      return rejectWithValue({ ...hasErrResponse });
    }
  }
);

export const quickOrderSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setQuery: (
      state,
      action: PayloadAction<{ instance: QuickOrderInstance; query: string }>
    ) => {
      state.query = action.payload.query;
      state.products = [];
    },
    setChosenShoppingList: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.chosenShoppingList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.products = action.payload;
    });
  },
});

export const { setQuery, setChosenShoppingList } = quickOrderSlice.actions;

export default quickOrderSlice.reducer;
