import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationState, ShowNotificationArgs } from '../Types/notification';

const sliceName = 'notification';
const initialState: NotificationState = {
  queue: [],
  hash: '',
};

const defaultTimeout = 2000;

export const notificationSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    showNotification: (state, action: PayloadAction<ShowNotificationArgs>) => {
      state.queue.push({
        nodeId: action.payload.nodeId || new Date().getTime().toString(),
        text: action.payload.text,
        timeout: action.payload.timeout || defaultTimeout,
      });
    },
    hideNotification: (state, action: PayloadAction<string>) => {
      state.queue = state.queue.filter((x) => x.nodeId !== action.payload);
    },
  },
});

export const { showNotification, hideNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
