import { Error } from './error';

export interface FacetedSearchState {
  subNavigation?: SubNavigationLink;
  sortCriteria: {
    sortItems: SortItem[];
    start: number;
    end: number;
    totalHits: number;
  };
  facetFilters: Filter[];
  navigationTheme: NavigationTheme;
  productsView?: string;
  productsViewCachedId: string | undefined;
  status: Status;
  errors?: Error;
  sortOrder?: string | null;
  sortDirection?: string | null;
  inStock?: boolean;
}

export type SubNavigationLink = {
  name: string;
  url: string;
  isSelected: boolean;
  links: SubNavigationLink[];
};

export type SortItem = {
  text: string;
  value: string;
  selected: boolean;
};

export type Filter = {
  label: string;
  id: string;
  selectedOptions: string[];
  singleSelect: boolean;
  options: FilterOption[];
};

export type FilterOption = {
  label: string;
  id: string;
  quantity: number;
};

export enum NavigationTheme {
  Category = 'category',
  Filter = 'filter',
}

export enum Status {
  Idle = 'idle',
  Loading = 'loading',
}

export enum SortParameters {
  SortBy = 'sort_by',
  SortDirection = 'sort_direction',
  InStock = 'in_stock',
}
