import { EditorString } from './shared';

export type SearchItem = {
  category: Group;
  name: EditorString | null;
  url?: string;
  hasImage: boolean;
  showAll: boolean;
};

export type ProductSearchItem = SearchItem & {
  image: string;
  price: string;
  articleNumber: string;
  brand: string;
};

export type QuickSearchResult = {
  pages: SearchItem[];
  products: ProductSearchItem[];
  categories: SearchItem[];
  news: SearchItem[];
};

export type QuickSearchState = {
  query: string;
  results: QuickSearchResult;
  hasResults: boolean;
  showResult: boolean;
  selectedItem: number;
  searchUrl: string;
  status: Status;
};

export enum Group {
  Products = 'products',
  Categories = 'categories',
  Pages = 'pages',
}

export enum Status {
  Idle = 'idle',
  Loading = 'loading',
}
