import { RequestOptions } from 'https';
import 'whatwg-fetch';

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export const get = (
  url: string,
  abortController?: AbortController
): Promise<any> => _fetch(url, 'GET', null, abortController);

export const post = (
  url: string,
  data: unknown,
  abortController?: AbortController
): Promise<Response> => _fetch(url, 'POST', data, abortController);

export const put = (
  url: string,
  data: unknown,
  abortController?: AbortController
): Promise<Response> => _fetch(url, 'PUT', data, abortController);

export const patch = (
  url: string,
  data: unknown,
  abortController?: AbortController
): Promise<any> => _fetch(url, 'PATCH', data, abortController);

export const remove = (
  url: string,
  data?: unknown,
  abortController?: AbortController
): Promise<any> => _fetch(url, 'DELETE', data || {}, abortController);

type Options = RequestOptions & {
  method: Method;
  headers: {
    'litium-request-context': string;
    'content-type'?: string;
    RequestVerificationToken?: string;
  };
  credentials: string;
  signal?: any;
  body?: string | null;
};

const _fetch = (
  url: string,
  method: Method,
  data?: unknown,
  abortController?: AbortController
) => {
  let options: RequestInit = {
    method,
    headers: {
      'litium-request-context': JSON.stringify(window.__litium.requestContext),
    },
    credentials: 'include',
  };

  if (abortController) {
    options.signal = abortController?.signal;
  }
  if (method !== 'GET') {
    options = {
      ...options,
      body: data ? JSON.stringify(data) : null,
      headers: {
        ...options.headers,
        'content-type': 'application/json',
      },
    };

    const token = window.localStorage.getItem('requestVerificationToken');
    if (token) {
      options = {
        ...options,
        headers: {
          ...options.headers,
          RequestVerificationToken: token,
        },
      };
    }
  }
  // use native browser implementation if it supports aborting
  const abortableFetch = 'signal' in new Request('') ? window.fetch : fetch;
  return abortableFetch(url, options).then(_checkStatus);
};

const _checkStatus = (response: Response) => {
  if (response.status >= 200 && response.status < 300) {
  } else {
    throw response;
  }

  return response;
};
